import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

// Icons
import {
  Search,
  Plus,
  Pencil,
  Info,
  FileBarChart2,
  CalendarDays,
  CalendarCheck,
  CalendarFold,
  CalendarOff,
  Anchor,
  Trash2,
  Eye,
  Download,
} from "lucide-react";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getActiveUsers as onGetUserList,
  getTaskDetails as onGetTaskDetails,
  getActiveDepartments as onGetActiveDepartments,
  getActiveTask as onGetActiveTask,
  getTaskBoard as onGetTaskBoard,
  addTask as onAddTask,
  updateTask as onUpdateTask,
  deleteTask as onDeleteTask,
  getTaskMetrix as onGetTaskMetrix,
  getUserProfile as onGetUserProfile,
} from "slices/thunk";
import { toast, ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import Pagination from "Common/Pagination";

const TaskManeger = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (user: any) => user.Users,
    (state, user) => ({
      dataList: state.taskBoard,
      taskMetrix: state.taskMetrix,
      userList: user.activeUsers,
      profileList: user.userProfile,
      departmentList: state.activedepartmentslist,
    })
  );

  const { dataList, userList, profileList, taskMetrix, departmentList} = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [users, setUser] = useState<any>([]);
  const [user, setProfile] = useState<any>([]);
  const [metrix, setMetrix] = useState<any>({});
  const [taskDepartment_id, setDepartment] = useState<any>('');
  const [taskType, setType] = useState<any>('');
  const [taskSearch, setSearch] = useState<any>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [departments, setDepartments] = useState<any>([]);
  const perPageData = 12;

  // Get Data
  useEffect(() => {
    dispatch(onGetUserList());
    dispatch(onGetUserProfile());
    dispatch(onGetActiveDepartments());
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setUser(userList?.data);
    setMetrix(taskMetrix?.data);
    setProfile(profileList?.data);
    setDepartments(departmentList?.data);
  }, [dataList, profileList, taskMetrix, userList, departmentList]);


  // Table Head Action On Right
  useEffect(() => {
    console.log('user profile', user);
    const tableHead = document.querySelectorAll("th");
    const lastTheadElement = tableHead[tableHead?.length - 1];
    lastTheadElement?.classList.add("ltr:text-right", "rtl:text-left");

    return () => {
      lastTheadElement?.classList.remove("ltr:text-right", "rtl:text-left");
    };
  });

  const Status = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Approved
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">
            Declined
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  //Task Columns
  const columns = useMemo(
    () => [
      {
        header: "Ref",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <Link to="#!"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}>
              {cell.row.original.ref_no}
            </Link>
          </>
        )
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true
      },

      // {
      //   header: "Type",
      //   accessorKey: "type",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <h6 className="mb-1">{cell.row.original.type}</h6>
              {/* <p className="text-slate-500 dark:text-zink-500">
                {cell.row.original.type}
              </p> */}
            </div>
          </>
        ),
      },
      {
        header: "Task",
        accessorKey: "task",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <b className="mb-1">
                {cell.row.original?.task?.length > 40
                  ? `${cell.row.original.task.slice(0, 40)}...`
                  : cell.row.original.task}
              </b>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original?.description?.length > 40
                  ? `${cell.row.original.description.slice(0, 40)}...`
                  : cell.row.original.description}
              </p>
            </div>
          </>
        ),
      },
      {
        header: "Created By",
        accessorKey: "username",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <b className="mb-1">{cell.row.original.username}</b>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.user_role}
              </p>
            </div>
          </>
        ),
      },
      {
        header: "Task Date",
        accessorKey: "due_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <b className="mb-1">Start: {cell.row.original.start_date}</b>
              <p className="text-slate-500 dark:text-zink-200">
                Due: {cell.row.original.due_date}
              </p>
            </div>
          </>
        ),
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            {cell.row.original.status === "todo" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-blue-100 border-blue-200 text-blue-500 dark:bg-blue-500/20 dark:border-blue-500/20">
                {/* {cell.getValue()} */} Todo
              </span>
            ) : cell.row.original.status === "inprogress" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
                {/* {cell.row.original.status} */} In Progress
              </span>
            ) : cell.row.original.status === "done" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
                {/* {cell.row.original.status} */} Done
              </span>
            ) : cell.row.original.status === "review" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-orange-100 border-orange-200 text-orange-500 dark:bg-orange-500/20 dark:border-orange-500/20">
                {/* {cell.row.original.status} */} Review
              </span>
            ) : cell.row.original.status === "backlog" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-zink-100 border-zink-200 text-zink-500 dark:bg-zink-500/20 dark:border-zink-500/20">
                {/* {cell.row.original.status} */} Backlog
              </span>
            // ) : cell.row.original.status === "completed" ? (
            //   <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            //     Completed
            //   </span>
            ) : cell.row.original.status === "canceled" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
                {/* {cell.row.original.status} */} canceled
              </span>
            ) : (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">
                {cell.row.original.status}
              </span>
            )}
          </>
        ),
      },
      {
        // action
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-end gap-2">

            <button type="button" className="px-2 py-1.5 text-xs text-green-500 bg-white border-green-500 btn btn-sm hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:bg-zink-700 dark:hover:bg-green-500 dark:ring-green-400/20 dark:focus:bg-green-500"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
              >
                View Details
            </button>
    
              {/* <Link
                to="#!"
                className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                onClick={() => {
                  const data = cell.row.original;
                  handleUpdateDataClick(data);
                }}
              >
                <Eye className="size-4" />
              </Link> */}

              {
                (user?.id == cell.row.original.created_by) && (cell.row.original.status === 'todo' || cell.row.original.status === 'inprogress' || cell.row.original.status === 'backlog') ?  
                <Link
                to="#!"
                className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                onClick={() => {
                  const data = cell.row.original;
                  onClickDelete(data);
                }}
              >
                <Trash2 className="size-4" />
              </Link>
              : <></>
              }
          </div>
        ),
      },
    ],
    []
  );

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
      setDeleteModal(true);
      if (cell.id) {
        setEventData(cell);
      }
    };
  
    const handleDelete = () => {
      if (eventData) {
        dispatch(onDeleteTask(eventData.id));
        setDeleteModal(false);
        // setShow(false);
        // setEventData("");
        // setIsEdit(false);
      }
    };
  
    // Update Data
    const handleUpdateDataClick = (ele: any) => {
      setEventData({ ...ele });
      setIsEdit(true);
      setShow(true);
    };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: (eventData && eventData.type) || "",
      task: (eventData && eventData.task) || "",
      category: (eventData && eventData.category) || "",
      description: (eventData && eventData.description) || "",
      assigned_to: (eventData && eventData.assigned_to) || "",
      priority: (eventData && eventData.priority) || "",
      user_id: (eventData && eventData.created_by) || "",
      start_date:
        (eventData &&
          new Date(eventData.start_date).toISOString().split("T")[0]) ||
        "",
      due_date:
        (eventData &&
          new Date(eventData.due_date).toISOString().split("T")[0]) ||
        "",
      attachment: (eventData && eventData.attachment) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please select task type"),
      task: Yup.string().required("Please fill your task"),
      category: Yup.string(),
      description: Yup.string(),
      assigned_to: Yup.string(),
      priority: Yup.string(),
      start_date: Yup.string(),
      due_date: Yup.string(),
      user_id: Yup.string(),
      attachment: Yup.string(),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateTask(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddTask(newData));
      }
      toggle();
    },
  });

  const categoryOptions = [
    { value: '', label: "Select department" },
    ...(departments?.map((department: any) => ({
      value: department.id,
      label: department.name,
    })) || [])
  ];
  
  const typeOptions = [
    { value: '', label: 'Select type' },
    { value: 'Rating', label: 'Rating' },
    { value: 'Industry Research', label: 'Industry Research' },
    { value: 'Macro Economic Research', label: 'Macro Economic Research' },
    { value: 'Company Research', label: 'Company Research' },
    { value: 'Training', label: 'Training' },
    { value: 'Consulting', label: 'Consulting' },
    { value: 'Special', label: 'Special' },
    { value: 'ITDesigns', label: 'ITDesigns' },
    { value: 'ITDevelopment', label: 'ITDevelopment' }
];

  // Search Data
  const filterSearchData = (value: any, e: any) => {
    // const search = e.target.value;
    const search = e?.value;

    if (value === 'department') {
      setDepartment(search);
    } else  if (value === 'type') {
      setType(search);  
    } else  if (value === 'search') {
      setSearch(e?.target.value);  
    }

    console.log(value, search);

    dispatch(
      onGetTaskBoard({ page: currentPage, per_page: perPageData, department_id: taskDepartment_id, type: taskType, user_role: '', status: '', search: taskSearch, from: '', to: '' })
    );

    dispatch(
      onGetTaskMetrix({ page: currentPage, per_page: perPageData, department_id: taskDepartment_id, type: taskType, user_role: '', status: '', search: taskSearch, from: '', to: '' })
    );
    const keysToSearch = ['type', 'task', 'ref_no', 'username', 'department', 'description'];
    filterDataBySearch(data, search, keysToSearch, setData);
  };

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  useEffect(() => {
    setDepartment(taskDepartment_id);
    setType(taskType);
    setSearch(taskSearch);
    setCurrentPage(currentPage);
    dispatch(
      onGetTaskBoard({ page: currentPage, per_page: perPageData, department_id: taskDepartment_id, type: taskType, user_role: '', status: '', search: taskSearch, from: '', to: '' })
    );

    dispatch(
      onGetTaskMetrix({ page: currentPage, per_page: perPageData, department_id: taskDepartment_id, type: taskType, user_role: '', status: '', search: taskSearch, from: '', to: '' })
    );

  }, [currentPage, perPageData, user, taskDepartment_id, taskType, taskSearch, dispatch]);

  return (
    <React.Fragment>
      <BreadCrumb
        title="Task Manager"
        pageTitle="Task Management"
      />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-12">
        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-700">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-750 rounded-md text-15 shrink-0">
                <CalendarDays />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp
                    end={metrix?.backlog?.total}
                    className="counter-value text-white"
                  />
                  {/* /
                  <CountUp
                    end={metrix?.backlog?.count}
                    className="counter-value text-white"
                  /> */}
                </h5>
                <p className="text-white">Total Backlogs</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-800">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-850 rounded-md text-15 shrink-0">
                <CalendarCheck />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={metrix?.pending?.total}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Total Pending</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-900">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-950 rounded-md text-15 shrink-0">
                <CalendarOff />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={metrix?.inprgress?.total}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Total Inprogress</p>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-1000">
              <div className="flex items-center justify-center size-12 rounded-md text-white bg-agusto-1050 text-15 shrink-0">
                <CalendarFold />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={metrix?.completed?.total}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Total completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  onChange={(e) => filterSearchData('search', e)}
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>

            <div className="xl:col-span-2">
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={categoryOptions}
                // isSearchable={false}
                defaultValue={categoryOptions[0]}
                onChange={(event: any) => filterSearchData('department', event)}
                // id="choices-department-options"
              />
            </div>
            <div className="xl:col-span-2">
              <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  options={typeOptions}
                  // isSearchable={false}
                  defaultValue={typeOptions[0]}
                  onChange={(event: any) => filterSearchData('type', event)}
                  // id="choices-type-options"
              />
            </div>
            <div className="xl:col-span-3 xl:col-start-10">
              <div className="flex gap-2 xl:justify-end">
                  <div>
                      <button type="button" className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><Download className="inline-block size-4" /> <span className="align-middle">Export</span></button>
                  </div>

                  <Link
                  to="#!"
                  data-modal-target="leaveModal"
                  onClick={toggle}
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  <Plus className="inline-block size-4" />
                  <span className="align-middle">New Task</span>
                </Link>
                  {/* <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><SlidersHorizontal className="size-4" /></button> */}
              </div>

            </div>

          </div>
          <div className="overflow-x-auto">
            {data && data?.length > 0 ? (
              <TableContainer
                isPagination={false}
                columns={columns || []}
                data={data || []}
                customPageSize={perPageData}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Data Yet</h5>
                  {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched more than 5+ Departments We did not find any
                    Departments for you search.
                  </p> */}
                </div>
              </div>
            )}

            <Pagination
              perPageData={perPageData}
              data={dataList ? dataList?.data : dataList}
              total={dataList ? dataList?.meta?.total : 0}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentdata={dataList ? dataList?.data : []}
            />
          </div>
        </div>
      </div>

      {/* here is the modal */}
      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        {/* addTaskModal */}
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Update Task" : "Create New Task"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-12">

              <div className="xl:col-span-4">
                <div>
                  <label
                    htmlFor="taskType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Type
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="taskType"
                    name="type"
                    disabled={ !!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                    onChange={validation.handleChange}
                    value={validation.values.type || ""}
                  >
                    <option value="">Select Type</option>
                    <option value="Rating">Rating</option>
                    <option value="Industry Research">Industry Research</option>
                    <option value="Macro Economic Research">
                      Macro Economic Research
                    </option>
                    <option value="Company Research">Company Research</option>
                    <option value="Training">Training</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Special">Special</option>
                    <option value="ITDesigns">IT - Designs</option>
                    <option value="ITDevelopment">IT -  Development</option>
                    {/* {types?.map((type: any) => (
                      <option key={type.id} value={type.id}>
                        {type.type_name}
                      </option>
                    ))} */}
                  </select>
                  {validation.touched.type && validation.errors.type ? (
                    <p className="text-red-400">{validation.errors.type}</p>
                  ) : null}
                </div>
              </div>

              <div className="xl:col-span-4">
                <div>
                  <label
                    htmlFor="priorityType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Priority
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="priorityType"
                    name="priority"
                    disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                    onChange={validation.handleChange}
                    value={validation.values.priority || ""}
                  >
                    <option value="">Select Priority</option>
                    <option value="V.High">Very High</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                    {/* {types?.map((type: any) => (
                      <option key={type.id} value={type.id}>
                        {type.type_name}
                      </option>
                    ))} */}
                  </select>
                  {validation.touched.priority && validation.errors.priority ? (
                    <p className="text-red-400">{validation.errors.priority}</p>
                  ) : null}
                </div>
              </div>

              <div className="xl:col-span-4">
                <label
                  htmlFor="statusInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="statusInput"
                  name="status"
                  disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                  onChange={validation.handleChange}
                  value={validation.values.status || ""}
                >
                  <option value="">Select Status</option>
                  <option value="todo">Todo</option>
                  <option value="inprogress">In progress</option>
                  <option value="done">Done</option>
                  <option value="review">Review</option>
                  {/* <option value="done">Completed</option> */}
                  <option value="backlog">Backlog</option>
                  <option value="canceled">Canceled</option>
                </select>
                {validation.touched.status && validation.errors.status ? (
                  <p className="text-red-400">{validation.errors.status}</p>
                ) : null}
              </div>

              {/* <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="categoryInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Category
                  </label>
                  <input
                    type="text"
                    id="categoryInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="category"
                    onChange={validation.handleChange}
                    value={validation.values.category || ''}
                  />
                  {validation.touched.category &&
                  validation.errors.category ? (
                    <p className="text-red-400">
                      {validation.errors.category}
                    </p>
                  ) : null}
                </div>
              </div> */}

              <div className="xl:col-span-12">
                <label
                  htmlFor="taskInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Task Title
                </label>
                <input
                  type="text"
                  id="taskInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Task Title"
                  name="task"
                  disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                  onChange={validation.handleChange}
                  value={validation.values.task || ""}
                />
                {validation.touched.task && validation.errors.task ? (
                  <p className="text-red-400">{validation.errors.task}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <div>
                  <label
                    htmlFor="descriptionInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Description
                  </label>
                  <textarea
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="descriptionInput"
                    rows={4}
                    name="description"
                    disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                    onChange={validation.handleChange}
                    value={validation.values.description || ""}
                  ></textarea>
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <p className="text-red-400">
                      {validation.errors.description}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="start_dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="start_date"
                  disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                  onChange={validation.handleChange}
                  value={validation.values.start_date || ""}
                />
                {validation.touched.start_date &&
                validation.errors.start_date ? (
                  <p className="text-red-400">{validation.errors.start_date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="due_dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Due Date
                </label>
                <input
                  type="date"
                  id="due_dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="due_date"
                  disabled={!!isEdit && user?.id !== validation.values.user_id || validation.values.status === 'done'}
                  onChange={validation.handleChange}
                  value={validation.values.due_date || ""}
                />
                {validation.touched.due_date && validation.errors.due_date ? (
                  <p className="text-red-400">{validation.errors.due_date}</p>
                ) : null}
              </div>

              {/* <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="attachmentInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Attachment
                  </label>
                  <input
                    type="file"
                    id="attachmentInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="attachment"
                    onChange={validation.handleChange}
                    value={validation.values.attachment || 0}
                  />
                  {validation.touched.attachment &&
                  validation.errors.attachment ? (
                    <p className="text-red-400">
                      {validation.errors.attachment}
                    </p>
                  ) : null}
                </div>
              </div> */}

              {/* {isEdit && ( */}
            </div>

            <div className="flex justify-end gap-2 mt-4">

            <button
                type="reset"
                data-modal-close="addTaskModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              
                {isEdit && user?.id === validation.values.user_id ?
                  <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                  {!!isEdit ? "Update Task" : "Create New Task"}
                  </button>
                : <></>
                }

                {!isEdit ?
                                      <button
                                      type="submit"
                                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                      >
                                      {!!isEdit ? "Update Task" : "Create New Task"}
                                      </button>
                                  
                : <></>
                }

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default TaskManeger;
